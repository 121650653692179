import React from 'react'
import PropTypes from 'prop-types'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Intro</h2>
          
          <p>how do you want to use them?
The first step in herb gardening is to decide what type of herbs you want to grow.  Herbs are useful plants so considering how you would like to use them is a great way to begin the decision making process.  Knowing what you would like to grow will help you evaluate your growing conditions and determine specific plant types and varieties.

The perfect place to grow
Many herbs are quite adaptable to a variety of growing conditions. Look to their native habitats for clues as to how diverse of a growing range they will tolerate.  For example, elderberry which is valued for its flavorful flowers and berries as well as medicinal uses will do happily in part-shade to full sun and prefers moist conditions.  A lot of the most popular culinary herbs such as basil, oregano, rosemary, sage, tarragon dill and parsley thrive in full sun (defined as 6 hours of direct sun).  Mint, chives, parsley and even cilantro will tolerate some shade. 

Climate
Egypt is divided by  Department of Agriculture into various climatic zones. may be entirely in one zone or in several zones. These zones indicate the average minimum temperatures in each region and thus relate to the winter hardiness of plants. The length of the growing season is defined by the last frost in the spring and the first one in the fall. It is important to recognize the characteristics of your zone and select herb plants accordingly. Zones should be used as a guideline, but should not be regarded as absolute.

Soil
The right soil will make a huge difference in the success of your herb garden. Many culinary herbs are native to Appropriate regions and require well drained soil. Many herbs that are perennials will also require this to survive the winter months as well.</p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Work</h2>
          
          <p>HOW WE WORK</p>
<p>
- Enquiries
Contact us, and one of our highly-trained customer service representatives will meet with you to discuss your particular needs, either on your premises or in a manner most convenient to you.
</p>
<p>
- The purchase System
Once you have placed your order, it is immediately tracked on our comprehensive order logging system. What this means in practice is that our operations and logistics teams instantly prepare for the delivery of your order, assigning room in our cold storage facilities and in our delivery fleet.
</p>
<p>
- Quality
With our strict attention to quality assurance, our produce arrives to high quality. And, after a final rigorous assessment by our quality control professionals, we dispatch it to you shortly afterwards.
</p>
<p>
- Delivery
We deliver your produce within hours of its arrival, as with our huge fleet we are able to deliver several times a day, whether day or night. Our fleet is monitored by satellite, meaning that we can check at any time to see exactly where your order is. And, there is always someone available who is responsible for your account, should you need to call or email. We deliver on time and to the place you specify.
</p>
<p>
- Post-Delivery
Once you have received your order, your account manager will follow up and address any outstanding issues or queries you might have, and deal promptly with them so you do not have to face any inconvenience. If you require advice or help with display or care of your order, we will be only too happy to assist you, as we have merchandis who are at your service.
</p>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
        
          <p>There are thousands of blogs out there dedicated to providing innovative, educational, interesting information on the topics of herbs, essential oils And the rest of our products and living more naturally. There are almost too many blogs to keep up to date with. That’s why we’ve created Herbs & Oils And the rest of our products World.

Found A Great Blog Post?
While we endeavor to find as many brilliant blog posts as possible, there is only a limited amount of time in the day. If you find a blog post that you think should be featured on the blog (or if you are a blogger and you think one of your blog posts is worthy) then simply send us an email using this contact form.

Stay Up To Date
Make sure you like Herbs & Oils And the rest of our products World on so that you are updated each time we find a new blog post worth sharing. Plus, subscribe to our email list to receive a weekly round-up with the best of the best from the blog.

For now, take a look around the blog and soak up all the wonderful things you can do with herbs and essential oils And the rest of our products World!

Enjoy,</p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
        <p>

We Are Looking Forward to Being in Contact With You!

If you have any question or inquiry, you are most welcome to get in contact Our team is happy to serve you in English, Arabic and German.

Write Us an Email
In order to get your inquiry to the right department, please select a suitable contact form:
</p>
<span><p>call Us:
<p><a href="tel:+20822130826">+20 082 21-30-826</a></p></p></span>
<span><p>Fax: <p><a href="fax:0020822130826">+20 082 21-30-826</a></p></p></span>
<p><a href="mailto: info@chemtradeline.com">info@chemtradeline.com</a></p>
          
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main